// Home Page
import "typeface-muli";
import React, { Component } from "react";
import Layout from "../components/Layout";
import IconDescription from "../components/IconDescription";
import { Parallax, Background } from "react-parallax";
import { deviceDetect, isMobileOnly } from "react-device-detect";
import { signupBeta } from "../firebase/userManagement";
import scrollToComponent from "react-scroll-to-component";
import styles from "./home.module.css";
import Logo from "../components/Images/MediumLogo";
import HomeScreenMockUp from "../components/Images/HomeScreenMockUp";
import TopParallax from "../components/Images/TopParallax";
import HomeBottomParallax from "../components/Images/HomeBottomParallax";
import SongSubmissionMockUp from "../components/Images/SongSubmissionMockUp";
import ReactGA from "react-ga";
ReactGA.initialize("UA-131420419-1");
ReactGA.pageview("/");

class Home extends Component {
    constructor() {
        super();
        this.state = {
            newUser: {},
            signedUp: false
        };

        this.updateByPropertyName = this.updateByPropertyName.bind(this);
        this.scrollTo = this.scrollTo.bind(this);
        this.submitNewUser = this.submitNewUser.bind(this);
        this.renderForm = this.renderForm.bind(this);
    }

    updateByPropertyName = (propertyName, value) => {
        const newUser = {
            ...this.state.newUser,
            [propertyName]: value
        };
        this.setState({ newUser });
    };

    submitNewUser = e => {
        e.preventDefault();

        let metadata = deviceDetect();
        let timeStamp = Date.now();

        signupBeta({ ...this.state.newUser, metadata, timeStamp });
        ReactGA.event({
            category: "User",
            action: "Signed up for beta",
            label: "Beta",
            value: 1
        });
        this.setState({ signedUp: "true" });
    };

    scrollTo = component => {
        let options = {
            offset: 0,
            duration: 1200,
            align: "top"
        };
        console.log(component);

        scrollToComponent(this[component], options);
    };

    renderForm = () => {
        const { signedUp } = this.state;

        if (!signedUp) {
            return (
                <div className={`center-container ${styles.formContainer}`}>
                    <h2 className={styles.signupText}>
                        Sign up to be a beta tester
                    </h2>
                    <form
                        onSubmit={this.submitNewUser}
                        className={styles.signupForm}
                    >
                        <input
                            required
                            type="text"
                            placeholder="Full Name"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "name",
                                    event.target.value
                                )
                            }
                            className={styles.inputField}
                        />

                        <input
                            required
                            type="email"
                            placeholder="Email"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "email",
                                    event.target.value
                                )
                            }
                            className={styles.inputField}
                        />

                        <select
                            className={styles.userTypeSlect}
                            placeholder="Email"
                            name="userType"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "type",
                                    event.target.value
                                )
                            }
                            required
                            defaultValue=""
                        >
                            <option value="" disabled hidden>
                                Select a user type...
                            </option>
                            <option value="director">Director</option>
                            <option value="artist">Artist</option>
                            <option value="both">Both</option>
                        </select>

                        <button type="submit" className={styles.submitButton}>
                            Submit
                        </button>
                    </form>
                </div>
            );
        } else {
            return (
                <div className={`center-container`}>
                    <h2 className={styles.signedUpSuccess}>
                        Sign up Complete!
                    </h2>
                </div>
            );
        }
    };

    render() {
        return (
            <Layout>
                <Parallax
                    disabled={isMobileOnly}
                    strength={500}
                    className={styles.topSection}
                >
                    <Background
                        className={`${styles.parallaxBG} ${styles.topParallax}`}
                    >
                        <TopParallax
                            style={isMobileOnly ? { height: "100%" } : {}}
                        />
                    </Background>
                    <div className={styles.overlay}>
                        <div className={`center-container ${styles.container}`}>
                            <div className={styles.logo}>
                                <Logo />
                            </div>
                            <h1 className={styles.subtitle}>
                                Connecting artists and labels to music video
                                directors
                            </h1>
                            <button
                                className={styles.signupButton}
                                onClick={() => this.scrollTo("betaSignup")}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </Parallax>
                <section className={styles.appSection}>
                    <div className={styles.phone}>
                        <HomeScreenMockUp />
                    </div>
                    <div className={styles.item0}>
                        <h3 className={styles.appHeader}>Artists</h3>
                        <IconDescription
                            description="Easily find directors"
                            icon="MdSearch"
                        />
                    </div>
                    <div className={styles.item1}>
                        <IconDescription
                            description="Quickly send proposals"
                            icon="MdSend"
                        />
                    </div>
                    <div className={styles.item2}>
                        <h3 className={styles.appHeader}>Directors</h3>
                        <IconDescription
                            description="Showcase your portfolio"
                            icon="MdStar"
                        />
                    </div>
                    <div className={styles.item3}>
                        <IconDescription
                            description="Be discovered by artists"
                            icon="MdVisibility"
                        />
                    </div>
                    <div className={styles.item4}>
                        <IconDescription
                            description="Seamlessly collaborate"
                            icon="MdCached"
                        />
                    </div>
                </section>
                <section className={styles.callToActionSection}>
                    <div className={styles.callToActionHeader}>
                        <h2 className={styles.jumboText}>
                            Creative Collaboration Made Simple
                        </h2>
                        <p className={styles.callToActionSubText}>
                            AXLRY offers a platform for artists and directors
                        </p>
                    </div>
                    <div className={styles.songSubmissionPhone}>
                        <SongSubmissionMockUp />
                    </div>
                    <div className={styles.howItWorksBlurb}>
                        <h3 className={styles.howItWorksSubHeader}>
                            Artists: Need a music video? Know your budget?
                        </h3>
                        <p className={styles.howItWorksText}>
                            Set your working budget for a music video. AXLRY
                            will connect you with up to 3 of your selected
                            directors in your budget range.
                        </p>
                        <h3 className={styles.howItWorksSubHeader}>
                            Directors: Showcase your portfolio and receive
                            requests for videos.
                        </h3>
                        <p className={styles.howItWorksText}>
                            Set your asking price, build your portfolio and chat
                            with users before committing to a project.
                        </p>
                        <button
                            className={styles.signupButtonHighContrast}
                            onClick={() => this.scrollTo("betaSignup")}
                        >
                            Sign Up for Beta
                        </button>
                    </div>
                </section>
                <Parallax
                    className={styles.formParallax}
                    disabled={isMobileOnly}
                    strength={500}
                >
                    <Background
                        id={"home"}
                        contentClassName={"hello-world"}
                        className={`${styles.parallaxBG} bottom-parallax`}
                    >
                        <HomeBottomParallax
                            style={isMobileOnly ? { height: "100%" } : {}}
                        />
                    </Background>
                    <section
                        ref={section => {
                            this.betaSignup = section;
                        }}
                        id="beta-signup"
                        className={styles.overlay}
                    >
                        {this.renderForm()}
                    </section>
                </Parallax>
            </Layout>
        );
    }
}

export default Home;
