// IconDescription component
import React from "react";
import PropTypes from "prop-types";
import * as Icon from "react-icons/md";
import styles from "./IconDescription.module.css";

const IconStyles = {
    color: "white",
    size: 45
};

const IconDescription = props => {
    const { icon, description } = props;
    const IconName = Icon[icon];

    return (
        <div className={styles.container}>
            <IconName
                size={IconStyles.size}
                color={IconStyles.color}
                className={styles.icon}
            />
            <p className={styles.description}> {description} </p>
        </div>
    );
};

IconDescription.propTypes = {
    icon: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

IconDescription.defaultProps = {
    icon: "FiStar",
    description: "lorem ipsum dolor sit amet"
};

export default IconDescription;
